import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Avatar} from '../components/avatar';
import {Button} from '../components/button';
import {WillAnimate} from '../components/content_animate';
import {InAnchorLink} from '../components/in_anchor_link';
import {
    avatarImage,
    fluid1200Width,
    fluid1600Width,
    fluid800Width,
    fullscreenHero,
    Layout,
    logoImage,
} from '../components/layout';
import {NumberHero} from '../components/number_hero';
import {ScrollTo} from '../components/scroll_to';

export default ({data}: any) => {
    return (
        <Layout title="Software engineers met hart voor de gebruiker" translatedPagePath="/en/">
            <WillAnimate as="div" name="hero" className="hero--home" id="hero">
                <div className="hero__content-wrapper">
                    <div className="hero__content">
                        <h1>
                            Samen <br />
                            producten <br />
                            creëren
                        </h1>
                        <h2>
                            Wij zijn software engineers met <br /> hart voor de gebruiker.
                        </h2>
                    </div>
                </div>
                <ScrollTo className="hero__scroll" target="/#from-hero" position="start" />

                <NumberHero />
            </WillAnimate>

            <section className="section">
                {/* TECHNIC STRATEGIC PARTNER */}
                <WillAnimate id="from-hero">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Technisch en <br />
                                strate&shy;gisch <br />
                                partner
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Sinds 2010 creëren en verbeteren we software&shy;producten,{' '}
                                <span className="highlight">samen</span> met onze opdrachtgevers. Daarbij zijn wij een
                                tech&shy;nisch en strategisch partner voor de lange termijn.
                            </p>
                            <p>
                                We realiseren moderne, complexe en schaalbare software&shy;producten die{' '}
                                <span className="highlight">gemakkelijk te gebruiken</span> zijn. Denk bij&shy;voorbeeld
                                aan <span className="highlight">webplatformen</span> en{' '}
                                <span className="highlight">SaaS-producten</span>.
                            </p>
                            <p>
                                We werken in een door&shy;lopend proces, waarbij de opdracht&shy;gever de rol van{' '}
                                <span className="highlight">product&shy;eigenaar</span> invult.
                            </p>
                        </div>
                        <div className="content__buttons content__buttons--50">
                            <Button url="/nl/werkwijze/" name="Onze werkwijze" />
                        </div>
                    </div>
                </WillAnimate>

                {/* CASES */}
                <article className="content">
                    <div className="container">
                        <Link
                            to="/nl/portfolio/saas-product-keeping"
                            title="Case: Ons eigen product: Keeping"
                            className="work-item-wrapper">
                            <WillAnimate as="div" className="work-item work-item--odd">
                                <div className="work-item__background" />
                                <div className="work-item__image">
                                    <Img alt="Keeping" fluid={data.keepingBarend.childImageSharp.fluid} />
                                </div>

                                <div className="work-item__content-wrapper">
                                    <div className="work-item__title">
                                        <h3>Ons eigen SaaS-product: Keeping</h3>
                                    </div>
                                    <div className="work-item__subtitle">
                                        <h4>Tijd&shy;registratie voor iedere onder&shy;nemer.</h4>
                                    </div>
                                    <div className="work-item__copy">
                                        <p>
                                            Breng orde in je tijd met{' '}
                                            <InAnchorLink
                                                name="Keeping"
                                                url="https://keeping.nl/ontdek-urenregistratie"
                                                title="Keeping urenregistratie"
                                            />
                                            , de eenvoudig te gebruiken tijd&shy;registratie&shy;applicatie. Keeping
                                            houdt de basis eenvoudig, maar is veelzijdig in gebruik.
                                        </p>
                                    </div>
                                    <Button name="Bekijk dit werk" />
                                </div>
                            </WillAnimate>
                        </Link>

                        <Link
                            to="/nl/portfolio/taxatieplatform-taxapi/"
                            className="work-item-wrapper"
                            title="Case: Taxatieplatform TaXapi">
                            <WillAnimate as="div" className="work-item work-item--even work-item--last">
                                <div className="work-item__background" />

                                <div className="work-item__image">
                                    <Img
                                        alt="taxatieplatform Taxapi in gebruik"
                                        fluid={data.taxapiMain.childImageSharp.fluid}
                                    />
                                </div>
                                <div className="work-item__content-wrapper">
                                    <div className="work-item__title">
                                        <h3>Taxatieplatform TaXapi</h3>
                                    </div>
                                    <div className="work-item__subtitle">
                                        <h4>Alles voor je taxaties binnen handbereik.</h4>
                                    </div>
                                    <div className="work-item__copy">
                                        <p>
                                            Samen met vastgoed&shy;taxatie&shy;veteranen ont&shy;wikkelden we TaXapi.{' '}
                                            Het platform maakt de uitvoering van een taxatie voor zowel de taxateur als{' '}
                                            de klant snel, prettig en leuk.
                                        </p>
                                    </div>
                                    <Button name="Bekijk dit werk" />
                                </div>
                            </WillAnimate>
                        </Link>
                    </div>
                </article>

                {/* CREATE NEW SOFTWARE */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Samen <br />
                                software <br />
                                ontwikk&shy;elen
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Heb je een idee voor een nieuw software&shy;product? We helpen je graag verder met onze
                                expertise.
                            </p>
                            <p>
                                Wij zijn gespecial&shy;iseerd in het creëren van web&shy;applicaties met{' '}
                                <span className="highlight">Laravel</span>, <span className="highlight">React</span> en{' '}
                                <span className="highlight">node.js</span>. Daarbij realiseren we ook{' '}
                                <span className="highlight">API's</span> en{' '}
                                <span className="highlight">webservices</span>. De applicatie&shy;infrastructuur richten
                                we schaalbaar en <span className="highlight">privacy&shy;bewust</span> in bij moderne
                                (cloud)&shy;providers.
                            </p>
                            <p>
                                De gebruiker staat altijd voorop. We besteden extra aandacht aan de gebruikers­ervaring
                                en hechten veel waarde aan principes zoals{' '}
                                <span className="highlight">Privacy&nbsp;by&nbsp;Design</span>.
                            </p>
                        </div>

                        <div className="content__buttons content__buttons--50">
                            <Button url="/nl/werkwijze/" name="Onze werkwijze" />
                        </div>
                    </div>
                </WillAnimate>

                {/* OFFICE */}

                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display content__display--avatars">
                            <h2>
                                Vanuit Enschede werken wij in een klein hecht team van specialisten. In een informele
                                werk&shy;omgeving creëren wij software&shy;producten.
                            </h2>
                        </div>
                        <div className="avatar-grid">
                            <div className="avatar-grid__background" />

                            <Avatar
                                name="Xander"
                                profession="Founder, UX Engineer"
                                image={data.peopleXander.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Olav"
                                profession="Founder, UX Engineer"
                                image={data.peopleOlav.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Nick"
                                profession="Software Engineer"
                                image={data.peopleNick.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Jaap"
                                profession="Software Engineer"
                                image={data.peopleJaap.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Irene"
                                profession="Office Manager"
                                image={data.peopleIrene.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Dirk-Jan"
                                profession="Software Engineer"
                                image={data.peopleDirk_jan.childImageSharp.fluid}
                            />

                            <Avatar
                                name="Jeroen"
                                profession="Software Engineer"
                                image={data.peopleJeroen.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                </WillAnimate>

                {/* SOFTWARE CONSULTANCY */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Technisch en <br />
                                strate&shy;gisch <br />
                                advies
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Heb je een bestaand software&shy;product? Wij adviseren en ondersteunen je graag bij het
                                oplossen van zowel <span className="highlight">technische-</span> als{' '}
                                <span className="highlight">UX</span> vraagstukken.
                            </p>
                            <p>
                                Ben je op zoek naar een aan&shy;trekkelijke en goede gebruikers&shy;ervaring? Of wil je
                                een unieke onder&shy;scheidende maar ook in&shy;gewikkelde functie toe&shy;voegen? Wij
                                helpen je verder.
                            </p>
                            <p>
                                Jouw product is en blijft jouw product. Of het nu om een nieuw of bestaand product gaat,{' '}
                                het <span className="highlight">intellectueel eigendom</span> dragen wij altijd aan jou
                                over.
                            </p>
                        </div>
                        <div className="content__buttons content__buttons--50">
                            <Button url="/nl/contact/" name="Neem contact met ons op" />
                        </div>
                    </div>
                </WillAnimate>

                {/* BRANDS */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display content__display--logos">
                            <h2>
                                We werken met onze opdrachtgevers aan softwareproducten binnen: ict, auto&shy;motive,
                                onderwijs, sport, tele&shy;communicatie, tourisme, overheid en vastgoed.
                            </h2>
                        </div>
                        <div className="logos-grid">
                            <div className="logos-grid__background" />
                            <div className="logos-grid__row">
                                <div className="logos-grid__item">
                                    <Img alt="Logo TaXapi" fluid={data.taxapi.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo Autosociaal" fluid={data.autosociaal.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo Gripvol" fluid={data.gripvol.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img
                                        alt="Logo 360 Sportsintelligence"
                                        fluid={data.sportsintelligence.childImageSharp.fluid}
                                    />
                                </div>
                            </div>

                            <div className="logos-grid__row">
                                <div className="logos-grid__item">
                                    <Img alt="Logo TravelTrustIt" fluid={data.traveltrustit.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo Itslearning" fluid={data.itslearning.childImageSharp.fluid} />
                                </div>
                                <div className="logos-grid__item">
                                    <Img
                                        alt="Logo Vertaalbureau Perfect"
                                        fluid={data.vertaalbureauPerfect.childImageSharp.fluid}
                                    />
                                </div>
                                <div className="logos-grid__item">
                                    <Img alt="Logo 123 Test" fluid={data.test.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                    </div>
                </WillAnimate>

                {/* JOBS */}
                <WillAnimate className="content">
                    <div className="container">
                        <div className="content__display">
                            <h2>
                                Werken bij <br />
                                Label305?
                            </h2>
                        </div>
                        <div className="content__copy">
                            <p className="p-lead">
                                Werk jij graag aan software&shy;producten met technische diepgang? Ben je creatief en
                                hou je van afwisseling? Dan is werken bij Label305 misschien iets voor jou.
                            </p>
                            <p>
                                We zijn een enthousiast, jong en hard&shy;werkend team met een leuke informele{' '}
                                werksfeer. We zijn niet te groot en dat houden we zo.
                            </p>
                            <p>
                                Bij ons is er ruimte voor <span className="highlight">technische verdieping</span>,{' '}
                                <span className="highlight">zelf&shy;ontplooiing</span> en werken aan{' '}
                                <span className="highlight">onze eigen producten</span>. We bieden een gezonde mix van{' '}
                                <span className="highlight">samenwerken</span> op kantoor, en vanaf thuis.
                            </p>
                        </div>
                        <div className="content__buttons content__buttons--50">
                            <Button url="https://careers.305.nl/?lang=nl" name="Alle vacatures en stages" />
                        </div>
                    </div>
                </WillAnimate>
            </section>
        </Layout>
    );
};

export const query = graphql`
    query {
        keepingBarend: file(relativePath: {eq: "portfolio/keeping/keeping-barend.jpg"}) {
            ...fluid800Width
        }
        taxapiMain: file(relativePath: {eq: "portfolio/taxapi/taxapi-main.jpg"}) {
            ...fluid800Width
        }
        autosociaal: file(relativePath: {eq: "logos/autosociaal-black@2x.png"}) {
            ...logoImage
        }
        gripvol: file(relativePath: {eq: "logos/gripvol-black@2x.png"}) {
            ...logoImage
        }
        sportsintelligence: file(relativePath: {eq: "logos/360-black@2x.png"}) {
            ...logoImage
        }
        itslearning: file(relativePath: {eq: "logos/itslearning-black@2x.png"}) {
            ...logoImage
        }
        test: file(relativePath: {eq: "logos/123-black@2x.png"}) {
            ...logoImage
        }
        taxapi: file(relativePath: {eq: "logos/taxapi-black@2x.png"}) {
            ...logoImage
        }
        traveltrustit: file(relativePath: {eq: "logos/traveltrustit-black@2x.png"}) {
            ...logoImage
        }
        vertaalbureauPerfect: file(relativePath: {eq: "logos/vertaalbureau-perfect-black@2x.png"}) {
            ...logoImage
        }
        patrick: file(relativePath: {eq: "logos/patrick-black@2x.png"}) {
            ...logoImage
        }
        overijssel: file(relativePath: {eq: "logos/overijssel-black@2x.png"}) {
            ...logoImage
        }
        peopleXander: file(relativePath: {eq: "people/xander_2.jpg"}) {
            ...avatarImage
        }
        peopleOlav: file(relativePath: {eq: "people/olav_2.jpg"}) {
            ...avatarImage
        }
        peopleNick: file(relativePath: {eq: "people/nick_2.jpg"}) {
            ...avatarImage
        }
        peopleJaap: file(relativePath: {eq: "people/jaap_2.jpg"}) {
            ...avatarImage
        }
        peopleIrene: file(relativePath: {eq: "people/irene_2.jpg"}) {
            ...avatarImage
        }
        peopleDirk_jan: file(relativePath: {eq: "people/dirk_jan_2.jpg"}) {
            ...avatarImage
        }
        peopleJeroen: file(relativePath: {eq: "people/jeroen_2.jpg"}) {
            ...avatarImage
        }
    }
`;
